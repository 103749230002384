import { LocalStorageService } from "../base/local.storage.service";
import { TokenDto } from "../pages/login/dto/token.dto";

export class AuthorityUtils {
  private static instance: AuthorityUtils = new AuthorityUtils();

  constructor(
  ) { }

  public static getInstance(): AuthorityUtils {
    if (!AuthorityUtils.instance) {
      AuthorityUtils.instance = new AuthorityUtils();
    }
    return AuthorityUtils.instance;
  }

  hasAuthorities(authStr: string, localStorageService: LocalStorageService): boolean {
    let token: TokenDto = localStorageService.get("tokenObj");
    if (token && token.roles) {
      let auth: string | undefined = token.authorities.find((authItem) => {
        return (authItem === authStr);
      });
      return auth !== undefined && auth !== null;
    }
    return false;
  }
  
}
