import { makeStateKey, StateKey } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";

export class ApiDto {
  private _path: string;
  private _key: StateKey<Object>;

  constructor(path: string) {
    this._path = path;
    this._key = makeStateKey(this.path);
  }

  set path(value: string) {
    this._path = value;
  }

  get path(): string {
    return this._path;
  }

  get key(): StateKey<Object> {
    return this._key;
  }

  fullPath(prefixPath: string = "charity/api/"): string {
    return environment.ApiRoot + prefixPath +  this.path;
  }

  get fullTokenPath(): string {
    return environment.ApiRootOauth + this.path;
  }

  get fullRootPath(): string {
    return environment.ApiRootOauth;
  }
}
