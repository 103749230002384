<div class="win-alert">
    <i class="fa fa-close action"></i>
    <div class="title">
        <span>{{title}}</span>
    </div>
    <div class="detail">
        <div *ngFor="let item of listMessages">
            <p *ngIf="item!='undefined'" [innerHtml]="item"></p>
        </div>
    </div>
</div>