import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ObjectUtils {
  constructor() { }

  public isValid(obj: any): boolean {
    return obj !== null && obj !== undefined;
  }

  isStringValid(str: string | undefined | null): boolean {
    return str !== null && str !== undefined && str.trim().length > 0;
  }

  formatNumberWithSeparator(number: Number | undefined, languageCode: string) {
    let separatorSymbol = languageCode === "vi" ? "." : ",";
    if (number && Number(number)) {
      return number.toString().replace(/\B(?=(\d{3})+\b)/g, separatorSymbol);
    }
    return number + "";
  }
}
