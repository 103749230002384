
import { HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
// import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "../base/local.storage.service";
import { TokenDto } from "../pages/login/dto/token.dto";
import { AuthService } from "./auth.service";
import { Broadcast, EVENT } from './broadcast';
import { AppPath, Authority } from "./constant";
import { AppBusiness } from "../app.bussines";
import { AuthorityUtils } from "../utilities/authority.utils";
import { AppStorageUtils } from "../utilities/app.storage.utils";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { PARAM } from "../enum/param";

@Injectable({ providedIn: "root" })
export class HttpRequestInterceptor implements HttpInterceptor {
    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private isRefeshingToken: boolean = false;

    constructor(
        private injector: Injector,
        private appBusiness: AppBusiness,
        private broadcast: Broadcast,
        private localStorageService: LocalStorageService,
        private router: Router,
        // private toastr: ToastrService
    ) {
    }

    addToken(req: HttpRequest<any>, _token: string): HttpRequest<any> {
        if (this.needToAppendHeader(req)) {
            return req.clone({
                setHeaders: {
                    language: this.localStorageService.get("language"),
                    Authorization: this.localStorageService.get("tokenObj").token_type + " " + this.localStorageService.get("tokenObj").access_token
                }
            });
        } else {
            return req;
        }
    }

    needToAppendHeader(request: HttpRequest<any>): boolean {
        let url = request.urlWithParams,
            needAppendHeader: boolean = false;
        
        if (url.indexOf(PARAM.NONE_AUTHENTICATION) === -1
            && url.indexOf("i18n") === -1
            && url.indexOf("googleapis.com") === -1
        ) {
            needAppendHeader = true;
        }
        return needAppendHeader;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const authService = this.injector.get(AuthService);
        if (this.needToAppendHeader(req) && !AuthorityUtils.getInstance().hasAuthorities(Authority.ACCESS_CRT, this.localStorageService)) {
            let errorObject = { "error": { "status": 401, "error": [{ "code": "no.access.authority", "message": "no.access.authority", "field": "system" }] } };
            return throwError(errorObject);
        }

        return next.handle(this.addToken(req, authService.getAuthToken())).pipe(tap((data: any) => {
            if (data.url && data.status) {
                //refresh token when expire in time has passed 1/2
                this.checkToken();
            }
        }),
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(req, next);
                        default:
                            return throwError(error);
                    }
                } else {
                    return throwError(error);
            }
            })
        );
        }

    checkToken(): void {
        let tokenExpireIn = new Date(this.localStorageService.get("tokenExpireIn")),
            now = new Date();
        if (tokenExpireIn < now && this.isRefeshingToken === false) {
            let token = this.localStorageService.get("tokenObj");
            if (token.refresh_token) {
                this.isRefeshingToken = true;
                this.broadcast.broadcast(EVENT.SHOW_SPINNER);
                this.appBusiness.refreshToken(token.refresh_token).subscribe(data => {
                    this.broadcast.broadcast(EVENT.HIDE_SPINNER);
                    this.localStorageService.save('access_token', data.access_token);
                    this.localStorageService.save('tokenObj', data);
                    //set token expire time
                    let now = new Date(),
                        expiredIn = new Date(now.getTime() + ((data.expires_in * 1000) / 2));
                    this.localStorageService.save('tokenExpireIn', expiredIn.toString());
                    this.isRefeshingToken = false;
                }, _errorRes => {
                    this.broadcast.broadcast(EVENT.HIDE_SPINNER);
                    this.isRefeshingToken = false;
                    //remove token and user infors
                    this.logoutUser();
                    // let language: string = AppStorageUtils.getInstance().getCurrentLanguageCode(this.localStorageService),
                    //     message = "Session expired",
                    //     title = "Error";

                    // if (language === "vi") {
                    //     message = "Phiên đăng nhập đã hết hạn.";
                    //     title = "Lỗi";
                    // }
                    // this.toastr.error(message, title);
                });
            }
        }
    }

    handle400Error(error: HttpErrorResponse) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }

        return throwError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        let token = this.localStorageService.get("tokenObj");
        if (token || token.refresh_token) {
            return this.logoutUser();
        }
        //401 when refresh token
        if (req.url.indexOf("authen/oauth/token") > -1 && req.body.indexOf("grant_type=refresh_token") > -1) {
            return this.logoutUser();
        }
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);
            const authService = this.injector.get(AuthService);
            return authService.refreshToken().pipe(
                switchMap((newToken: TokenDto) => {
                    if (newToken) {
                        this.localStorageService.save('access_token', newToken.access_token);
                        this.localStorageService.save('tokenObj', newToken);
                        this.tokenSubject.next(newToken.refresh_token);
                        return next.handle(this.addToken(this.getNewRequest(req), newToken.refresh_token));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(_error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(this.getNewRequest(req), token!));
                })
            );
        }
    }

    /*
        This method is only here so the example works.
        Do not include in your code, just use 'req' instead of 'this.getNewRequest(req)'.
    */
    getNewRequest(req: HttpRequest<any>): HttpRequest<any> {
        return req;
    }

    logoutUser() {
        // this.modalService.dismissAll();
        this.broadcast.broadcast(EVENT.COLLAPSE_SIDE_BAR);
        //remove token and user infors
        AppStorageUtils.getInstance().removeAllLoginInfo(this.localStorageService);
        //navigate to login page
        if (this.router.url.indexOf(AppPath.home.path) === -1) { 
            if (this.router.url.indexOf(AppPath.login.path) === -1) {
                this.router.navigate([AppPath.login.path], {
                    queryParams: { "redirectUrl": this.router.url }
                });
                } else {
                this.router.navigate([AppPath.login.path]);
            }
        }

        let errorObject = { "error": { "status": 400, "error": [{ "code": "invalid_grant", "message": "invalid_grant", "field": "system" }] } };
        return throwError(errorObject);
    }

}