import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpParams,
  HttpClient,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
// import { Observable } from "rxjs/internal/Observable";
import { Observable } from "rxjs";

interface IHttpOptions {
  body?: any;
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
}

@Injectable({ providedIn: "platform" })
export class HttpRequestClient extends HttpClient {
  constructor(handler: HttpHandler) {
    super(handler);
  }

  request<T>(
    first: string | HttpRequest<any>,
    url?: string,
    options: IHttpOptions = Object()
  ): Observable<T> {
    if (!options) options = Object();
    if (!options.headers) options.headers = new HttpHeaders();
    if (typeof first !== "string" && !first.headers) {
      first = (first as HttpRequest<any>).clone({
        headers: new HttpHeaders()
      });
    }

    if (typeof first !== "string")
      first = (first as HttpRequest<any>).clone({
        withCredentials: true
      });
    // options.withCredentials = true;
    //@ts-ignore
    return super.request(first as any, url, options);
  }
}
