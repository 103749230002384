import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from "../../base/local.storage.service";
import { Broadcast, EVENT } from "../../base/broadcast";
import { SidebarDto } from "../sidebar/dto/sidebar.dto";
import { AppPath, PageMode } from '../../base/constant';
import { RoleUtils } from '../../utilities/role.utils';

@Injectable({ providedIn: "root" })
export class AlwaysAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private broadcast: Broadcast) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url: string = decodeURIComponent(state.url);
    if (
      this.isValid(url, (AppPath.slash + AppPath.main.path))
      && (this.localStorageService.get("mode").pageMode === PageMode.MEMBER_MODE
        || !this.localStorageService.get("mode").pageMode && RoleUtils.getInstance().hasMemberRole(this.localStorageService)
      )
    ) {
      this.router.navigate([AppPath.main.my.path]);
      return false;
    } else if (
      this.isValid(url, (AppPath.slash + AppPath.main.my.path))
      && this.localStorageService.get("mode").pageMode === PageMode.ADMIN_MODE
    ) {
      this.router.navigate([AppPath.main.path]);
      return false;
    }

    let token = this.localStorageService.get('access_token'),
      token_active = route.params.token;
    // set sidebar active item
    let sidebarObj = new SidebarDto();
    sidebarObj.name = url.split("/")[1];
    sidebarObj.url = url;
    let resetPasswordUrl = decodeURIComponent((AppPath.slash + AppPath.resetPassword.path.replace(":token", token_active)));
    this.broadcast.broadcast(EVENT.UPDATE_SIDEBAR_SELECTED, sidebarObj);

    if (this.isValid(url, resetPasswordUrl) == true) {
      token = "";
    }
    let barCode: string = route.params.barCode;
    if (
      (
        this.isValid(url, (AppPath.slash + AppPath.login.path)) == false
        && this.isValid(url, (AppPath.slash + AppPath.forgotPassword.path)) == false
        && !(barCode && barCode.length > 0)
        && this.isValid(url, (AppPath.slash + AppPath.user.path + AppPath.slash + AppPath.user.action.active.path.replace(":token", token_active))) == false
        && this.isValid(url, resetPasswordUrl) == false
      )
      && !this.isLoggedIn(token)
    ) {
      this.router.navigate([AppPath.login.path]);
      return false;
    };
    if (
      (
        this.isValid(url, (AppPath.slash + AppPath.login.path)) == true
        || this.isValid(url, (AppPath.slash + AppPath.forgotPassword.path)) == true
        || this.isValid(url, resetPasswordUrl) == true
      )
      && this.isLoggedIn(token)
    ) {
      this.router.navigate([AppPath.home.path]);
      return true;
    } else if (
      (this.isValid(url, (AppPath.slash + AppPath.login.path)) == true)
      || this.isValid(url, (AppPath.slash + AppPath.forgotPassword.path)) == true
      || (barCode && barCode.length > 0
        && this.isValid(url, (AppPath.slash + AppPath.event.action.detail.path).replace(":barCode", route.params.barCode)) == true)
      || this.isValid(url, (AppPath.slash + AppPath.user.path + AppPath.slash + AppPath.user.action.active.path.replace(":token", token_active))) == true
      || this.isValid(url, resetPasswordUrl) == true
    ) {
      return true;
    };
    return true;
  }

  isLoggedIn(token: any) {
    return Object.keys(token).length > 0;
  }

  isValid(url: string, path: string): boolean {
    let valid = false;
    valid = url.indexOf(path) > -1 ? true : false;
    return valid;
  }
}