import { Component, ChangeDetectorRef } from "@angular/core";
import { Broadcast, EVENT } from "../../base/broadcast";

@Component({
  selector: "sd-spinner",
  templateUrl: "./spinner.html",
  styleUrls: ["./spinner.scss"]
})
export class SpinnerComponent {
  public isShow: boolean = false;
  constructor(
    private broadcast: Broadcast,
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
  }

  ngOnInit() {
    this.broadcast.on<EVENT>(EVENT.SHOW_SPINNER).subscribe(_message => {
      this.isShow = true;
      this.ref.detectChanges();
    });
    this.broadcast.on<EVENT>(EVENT.HIDE_SPINNER).subscribe(_message => {
      this.isShow = false;
      this.ref.detectChanges();
    });
  }
}
