export class SidebarItemDto {
    private _name: string;
    private _iconActive: string;
    private _iconDeactive: string;
    private _url: string;

    constructor() {
        this._name = "";
        this._iconActive = "";
        this._iconDeactive = "";
        this._url = "";
    }

    public get url(): string {
        return this._url;
    }
    public set url(value: string) {
        this._url = value;
    }

    public get iconDeactive(): string {
        return this._iconDeactive;
    }
    public set iconDeactive(value: string) {
        this._iconDeactive = value;
    }

    public get iconActive(): string {
        return this._iconActive;
    }
    public set iconActive(value: string) {
        this._iconActive = value;
    }

    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
}