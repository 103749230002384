import { Injectable } from "@angular/core";

@Injectable({ providedIn: "platform" })
export class LocalStorageService {
  
  private key: string = "viatech";

  save(name: string, data: any) {
    let localData: any = localStorage.getItem(this.key);
    if (localData) {
      localData = JSON.parse(localData);
    } else {
      localData = Object();
    }

    localData[name] = data;

    localStorage.setItem(this.key, JSON.stringify(localData));
  }

  get(name: string) {
    let value: string | null = localStorage.getItem(this.key);
    let data: any = value ? JSON.parse(value) : undefined;
    if (!data) {
      return undefined;
    }
    if (name) {
      if (data[name]) {
        return data[name];
      } else {
        return {};
      }
    }
    return data;
  }

  removeByname(name: string) {
    let value: string | null = localStorage.getItem(this.key);
    let data: any = value ? JSON.parse(value) : undefined;
    if (!data) {
      return;
    }
    if (name) {
      if (data[name]) {
        delete data[name];
      }
    }
    localStorage.setItem(this.key, JSON.stringify(data));
  }

  remove() {
    localStorage.clear();
  }
}
