import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { API } from "../../base/api";
import { ApiDto } from "../../shared/dto/api.dto";
import { Broadcast, EVENT } from "../../base/broadcast";
import { AppPath, AppConfig, PageMode } from "../../base/constant";
import { LocalStorageService } from "../../base/local.storage.service";
import { UserDto } from "../../pages/user/dto/user.dto";
import { RoleUtils } from "../../utilities/role.utils";
import { AppStorageUtils } from "../../utilities/app.storage.utils";
import { fromEvent, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "sd-header-bar",
  templateUrl: "./header.bar.html",
  styleUrls: ["./styles.scss"]
})
export class HeaderBarComponent implements OnInit {
  userInfo?: UserDto = undefined;
  username: string = "";
  currentLanguageCode?: string = undefined;
  listenLoggedInEvent: any = undefined;
  listenChangeAvatarEvent: any = undefined;
  mode?: string = undefined;
  userAvatar: string = "";
  downloadFileApiModel: ApiDto = new ApiDto(API.DOWNLOAD_FILE);
  listAvailableLanguages: any = AppConfig.availableLanguages;
  private scrollSubject = new Subject();
  private scrollObservable = this.scrollSubject.asObservable();

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private broadcast: Broadcast
  ) { }

  ngOnInit(): void {
    this.listenLoggedInEvent = this.broadcast.on<EVENT>(EVENT.LOGGED_IN).subscribe(_message => {
      this.getUserInfo();
    });
    this.listenChangeAvatarEvent = this.broadcast.on<EVENT>(EVENT.CHANGE_AVATAR).subscribe(message => {
      this.userAvatar = message.toString();
    });
    this.getUserInfo();
  }

  ngOnDestroy() {
    this.scrollSubject.next();
  }

  ngAfterViewInit() { 
    fromEvent(window, 'click').pipe(takeUntil(this.scrollObservable))
        .subscribe((event: any) => {
          if (!event.target.matches('.dropbtn')) {
            let dropdowns = document.getElementsByClassName("dropdown-content");
            for (let i = 0; i < dropdowns.length; i++) {
              dropdowns[i].classList.remove('show');
            }
          }
      });
  }

  toggleMode() { 
    document.getElementById("languageDropdown")?.classList.remove("show");
    document.getElementById("modeDropdown")?.classList.toggle("show");
  }

  toggleLanguage() { 
    document.getElementById("modeDropdown")?.classList.remove("show");
    document.getElementById("languageDropdown")?.classList.toggle("show");
  }

  getUserInfo() {
    let userAvatar = this.localStorageService.get('userAvatar');
    this.userAvatar = typeof (userAvatar) === "string" ? userAvatar : "";
    this.mode = RoleUtils.getInstance().hasMultipleMode(this.localStorageService) ? this.localStorageService.get("mode").pageMode : undefined;
    this.userInfo = this.localStorageService.get('userDetail');
    let lang = this.localStorageService.get('language');
    if (lang) {
      this.currentLanguageCode = lang;
    } else {
      this.currentLanguageCode = AppConfig.defaultLanguage;
    }
    if (this.userInfo) {
      this.username = this.userInfo.fullName?this.userInfo.fullName: this.userInfo.username;
    }
  }

  logout(): void {
    this.clearLocalStorage();
    //navigate to login page
    this.router.navigate([AppPath.login.path]);
  }

  clearLocalStorage() {
    this.broadcast.broadcast(EVENT.COLLAPSE_SIDE_BAR);
    //remove token and user infors
    AppStorageUtils.getInstance().removeAllLoginInfo(this.localStorageService);
    //navigate to login page
    this.router.navigate([AppPath.login.path]);
  }

  changeLanguage(languageCode: string) {
    if (languageCode !== AppStorageUtils.getInstance().getCurrentLanguageCode(this.localStorageService)) {
      this.translateService.use(languageCode);
      this.currentLanguageCode = languageCode;
      this.localStorageService.save("language", languageCode);
      this.broadcast.broadcast(EVENT.LANGUAGE_CHANGE, languageCode);
    }
  }

  changePageMode(mode: string) {
    if (mode !== this.mode && this.userInfo) {
      this.mode = mode;
      this.localStorageService.save("mode", { pageMode: mode, userLogined: this.userInfo.code });

      switch (this.mode) {
        case PageMode.ADMIN_MODE:
          this.router.navigate([AppPath.main.path]);
          break;
        case PageMode.MEMBER_MODE:
          this.router.navigate([AppPath.main.my.path]);
          break;
        default:
          break;
      }
    }
  }
}
