import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local.storage.service';
import { ApiDto } from '../shared/dto/api.dto';
import { TokenDto } from '../pages/login/dto/token.dto';
import { Observable } from 'rxjs';
import { API } from './api';
import { PARAM } from '../enum/param';
// import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: "root" })
export class AuthService {
    // Assuming this would be cached somehow from a login call.
    public authTokenStale: string = 'stale_auth_token';
    public authTokenNew: string = 'new_auth_token';
    public currentToken: string;

    constructor(
        private localStorageService: LocalStorageService,
        private http: HttpClient
    ) {
        this.currentToken = this.authTokenStale;
    }

    getAuthToken() {
        return this.currentToken;
    }

    refreshToken(): Observable<TokenDto> {
        /*
            The call that goes in here will use the existing refresh token to call
            a method on the oAuth server (usually called refreshToken) to get a new
            authorization token for the API calls.
        */

        let token = this.localStorageService.get("tokenObj");
        let getTokenApi: ApiDto = new ApiDto(API.PUBLISH.GET_TOKEN);
        let headers = new HttpHeaders();
        headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
        headers = headers.set(
            "Authorization",
            "Basic Y2hhcml0eS1jbGllbnQ6Y2hhcml0eS1zZWNyZXQ="
            // "Basic d2luLWNsaWVudDp3aW4tc2VjcmV0"
        );

        const body = new HttpParams()
            .set("refresh_token", token.refresh_token)
            .set("grant_type", "refresh_token");

        const params = new HttpParams()
            .set(PARAM.NONE_AUTHENTICATION, "true");
        return this.http.post<TokenDto>(
            getTokenApi.fullTokenPath,
            body.toString(),
            {
                headers: headers,
                params: params
            }
        );
    }
}