import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { fromEvent } from "rxjs";
import { AppConfig } from "./base/constant";
import { LocalStorageService } from "./base/local.storage.service";
import { GoogleAnalyticsService } from "./shared/google.analytics.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  public canShow: boolean = false;

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {

    this.router.events.subscribe(event => {
      this.googleAnalyticsService.subscribeRouterEvents(event);
    });
    
    let language = this.localStorageService.get("language")
    if (!language) {
      this.localStorageService.save("language", AppConfig.defaultLanguage);
      language = AppConfig.defaultLanguage;
    }
    this.translate.setDefaultLang(language);
    //wait for language is loaded
    this.translate.get('english').subscribe((_res: string) => {
      this.canShow = true;
    });

    fromEvent(window, 'popstate')
      .subscribe((_e) => {
        location.reload();
      });
  }
}
