import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { Broadcast } from "../base/broadcast";
import { HttpRequestClient } from "../base/http.request.client";
import { HttpRequestInterceptor } from "../base/http.request.interceptor";
import { LocalStorageService } from "../base/local.storage.service";
import { ObjectUtils } from "../utilities/object.utils";
import { AppBusiness } from "../app.bussines";
import { DatePipe, DecimalPipe } from "@angular/common";
import { GoogleAnalyticsService } from "./google.analytics.service";

const PROVIDERS = [
  DecimalPipe,
  DatePipe,
  Broadcast,
  ObjectUtils,
  LocalStorageService,
  GoogleAnalyticsService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true
  },
  HttpRequestClient,
  AppBusiness,
];

@NgModule({
  imports: [
    HttpClientModule
  ],
  declarations: [
  ],
  exports: [
  ],
  entryComponents: [
  ]
})
export class ServicesCoreModule {
  static forRoot(): ModuleWithProviders<ServicesCoreModule> {
    return {
      ngModule: ServicesCoreModule,
      providers: PROVIDERS
    };
  }
}

