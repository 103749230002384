import { AppPath, Role, PageMode } from "../../base/constant";
import { SidebarItemDto } from "./dto/item.dto";
import { LocalStorageService } from "../../base/local.storage.service";
import { RoleUtils } from "../../utilities/role.utils";

export class SidebarConfig {
  private _listPages: Array<SidebarItemDto> = new Array<SidebarItemDto>();

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  public get listPages(): Array<SidebarItemDto> {
    return this._listPages;
  }
  public set listPages(value: Array<SidebarItemDto>) {
    this._listPages = value;
  }

  public loadSidebarConfigByRole(roles: Array<string>): Array<SidebarItemDto> {
    let refineListPages: Array<SidebarItemDto> = [];
    let adminGroup: string | undefined = roles.find((role) => {
      return (role === Role.ADMIN || role === Role.SUPER_ADMIN);
    });
    let orgAdmin: string | undefined = roles.find((role) => {
      return (role === Role.ORG_ADMIN);
    });
    let restaurantManager: string | undefined = roles.find((role) => {
      return (role === Role.MANAGER);
    });
    let member: string | undefined = roles.find((role) => {
      return (role === Role.MEMBER);
    });
    if (this.localStorageService.get("mode").pageMode === PageMode.MEMBER_MODE && RoleUtils.getInstance().hasMemberRole(this.localStorageService)) {
      this.listPages = this.getSidebarConfigForMember();
    } else {
      if (adminGroup === Role.ADMIN || adminGroup === Role.SUPER_ADMIN) {
        this.listPages = this.getSidebarConfigForAdmin();
      } else if (orgAdmin === Role.ORG_ADMIN) {
        this.listPages = this.getSidebarConfigForOrgAdmin();
      } else if (restaurantManager === Role.MANAGER) {
        this.listPages = this.getSidebarConfigForResAdmin();
      } else if (member === Role.MEMBER) {
        this.listPages = this.getSidebarConfigForMember();
      }
    }

    this.listPages.forEach(page => {
      if (page) {
        refineListPages.push(page);
      }
    });
    this.listPages = refineListPages;

    return this.listPages;
  }

  private getSidebarConfigForAdmin(): Array<SidebarItemDto> {
    let data: Array<SidebarItemDto> = new Array<SidebarItemDto>();
    data.push(this.getHomeSidebarItem(), this.getOrgSidebarItem(), this.getUserSidebarItem(), this.getItemSidebarItem(), this.getEventSidebarItem(Role.ADMIN), this.getSupportSidebarItem(), this.getAboutSidebarItem());
    return data;
  }

  private getSidebarConfigForOrgAdmin(): Array<SidebarItemDto> {
    let data: Array<SidebarItemDto> = new Array<SidebarItemDto>();

    data.push(this.getHomeSidebarItem(), this.getResSidebarItem(), this.getUserSidebarItem(), this.getItemSidebarItem(), this.getEventSidebarItem(Role.ORG_ADMIN), this.getSupportSidebarItem(), this.getUnassignedSupportSidebarItem(), this.getAboutSidebarItem());

    return data;
  }

  private getSidebarConfigForResAdmin(): Array<SidebarItemDto> {
    let data: Array<SidebarItemDto> = new Array<SidebarItemDto>();
    data.push(this.getHomeSidebarItem(), this.getResSidebarItem(), this.getEventSidebarItem(Role.MANAGER), this.getSupportSidebarItem(), this.getUnassignedSupportSidebarItem(), this.getAboutSidebarItem());
    return data;
  }

  private getSidebarConfigForMember(): Array<SidebarItemDto> {
    let data: Array<SidebarItemDto> = new Array<SidebarItemDto>();
    data.push(this.getMyWineSidebarItem(), this.getMySearchEventSidebarItem(), this.getMyRestaurantSidebarItem(), this.getMyTicketsSidebarItem(), this.getMyContractSidebarItem(), this.getAboutSidebarItem());
    return data;
  }

  private getMyWineSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.myWine";
    sidebarItem.iconActive = "/assets/svg/ic_home_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_home.svg";
    sidebarItem.url = AppPath.slash + AppPath.main.my.path;
    return sidebarItem;
  }

  private getMySearchEventSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.mySearchEvent";
    sidebarItem.iconActive = "/assets/svg/Search_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/Search.svg";
    sidebarItem.url = AppPath.slash + AppPath.mySearchEvent.path;
    return sidebarItem;
  }

  private getHomeSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.main";
    sidebarItem.iconActive = "/assets/svg/ic_home_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_home.svg";
    sidebarItem.url = AppPath.slash + AppPath.main.path;
    return sidebarItem;
  }

  private getResSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.organization";
    sidebarItem.iconActive = "/assets/svg/ic_organization_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_organization.svg";
    sidebarItem.url = AppPath.slash + AppPath.organization.path + AppPath.slash + AppPath.organization.my.path;
    return sidebarItem;
  }

  private getOrgSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.organization";
    sidebarItem.iconActive = "/assets/svg/ic_organization_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_organization.svg";
    sidebarItem.url = AppPath.slash + AppPath.organization.path;
    return sidebarItem;
  }

  private getUserSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.user";
    sidebarItem.iconActive = "/assets/svg/ic_usermanagement_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_usermanagement.svg";
    sidebarItem.url = AppPath.slash + AppPath.user.path;
    return sidebarItem;
  }

  private getEventSidebarItem(role: string): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.event";
    sidebarItem.iconActive = "/assets/svg/ic_event_management_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_event_management.svg";
    if (role === Role.ADMIN || role === Role.SUPER_ADMIN) {
      sidebarItem.url = AppPath.slash + AppPath.event.path;
    } else if (role === Role.ORG_ADMIN || role === Role.MANAGER) {
      sidebarItem.url = AppPath.event.path + AppPath.slash + AppPath.event.my.path;
    }
    return sidebarItem;
  }

  private getItemSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.item";
    sidebarItem.iconActive = "/assets/svg/ic_contract_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_contract.svg";
    sidebarItem.url = AppPath.slash + AppPath.item.path;
    return sidebarItem;
  }

  private getSupportSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.support";
    sidebarItem.iconActive = "/assets/svg/my_ticket_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/my_ticket.svg";
    sidebarItem.url = AppPath.slash + AppPath.support.path;
    return sidebarItem;
  }

  private getUnassignedSupportSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.unassignedSupport";
    sidebarItem.iconActive = "/assets/svg/ic_verify_Active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_verify.svg";
    sidebarItem.url = AppPath.slash + AppPath.unassignedSupport.path;
    return sidebarItem;
  }

  private getAboutSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.profile";
    sidebarItem.iconActive = "/assets/svg/ic_setting_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_setting.svg";
    sidebarItem.url = AppPath.slash + AppPath.profile.path;
    return sidebarItem;
  }

  private getMyTicketsSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.myTickets";
    sidebarItem.iconActive = "/assets/svg/my_ticket_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/my_ticket.svg";
    sidebarItem.url = AppPath.slash + AppPath.myTickets.path;
    return sidebarItem;
  }

  private getMyRestaurantSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.myRestaurant";
    sidebarItem.iconActive = "/assets/svg/ic_organization_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_organization.svg";
    sidebarItem.url = AppPath.slash + AppPath.myRestaurant.path;
    return sidebarItem;
  }

  private getMyContractSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.myContract";
    sidebarItem.iconActive = "/assets/svg/ic_contract_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_contract.svg";
    sidebarItem.url = AppPath.slash + AppPath.myContract.path;
    return sidebarItem;
  }

  private getVerifySidebarItem(): SidebarItemDto {
      let sidebarItem: SidebarItemDto = new SidebarItemDto();
      sidebarItem.name = "home.sidebar.verify";
      sidebarItem.iconActive = "/assets/svg/ic_verify_Active.svg";
      sidebarItem.iconDeactive = "/assets/svg/ic_verify.svg";
    sidebarItem.url = AppPath.slash + AppPath.verify.path;
      return sidebarItem;
  }

  private getContractSidebarItem(): SidebarItemDto {
    let sidebarItem: SidebarItemDto = new SidebarItemDto();
    sidebarItem.name = "home.sidebar.contract";
    sidebarItem.iconActive = "/assets/svg/ic_contract_active.svg";
    sidebarItem.iconDeactive = "/assets/svg/ic_contract.svg";
    sidebarItem.url = AppPath.slash + AppPath.contract.path;
    return sidebarItem;
  }
}
