import { ModuleWithProviders, NgModule } from "@angular/core";
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from "../base/base.component";
// import { StatusPipe } from "../components/pipe/status";

@NgModule({
  imports: [
    TranslateModule,
  ],
  declarations: [
    BaseComponent,
    // StatusPipe,
  ],
  exports: [
    BaseComponent,
    TranslateModule,
    // StatusPipe,
  ],
  entryComponents: [
  ],
  providers: [
  ]
})
export class AppCoreModule {
  static forRoot(): ModuleWithProviders<AppCoreModule> {
    return {
      ngModule: AppCoreModule
    };
  }
}

