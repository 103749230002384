<div id="header-bar">
    <div style="text-align: right;">

        <!-- <div *ngIf="mode" class="dropdown">
            <div class="dropbtn btn-group changePageMode" (click)="toggleMode()">
                {{mode | translate}}
            </div>
            <div id="modeDropdown" class="dropdown-content m-t-20">
                <button type="button" class="dropdown-item" (click)="changePageMode('ADMIN_MODE')">{{"ADMIN_MODE"| translate}}</button>
                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item" (click)="changePageMode('MEMBER_MODE')">{{"MEMBER_MODE"| translate}}</button>
            </div>
        </div>

        <div *ngIf="currentLanguageCode" class="dropdown change-language-wrapper">
            <div class="dropbtn btn-group" (click)="toggleLanguage()">
                <img class="dropbtn" src="./assets/images/{{currentLanguageCode}}.png" alt="{{currentLanguageCode}}">
            </div>
            <div id="languageDropdown" class="dropdown-content m-t-20">
                <div *ngFor="let item of listAvailableLanguages; let l=last;">
                    <button type="button" class="dropdown-item" (click)="changeLanguage(item.value)">
                        <img alt="{{item.name| translate}}" class="language-item" src="./assets/images/{{item.value}}.png">{{item.name}}</button>
                    <div *ngIf="!l" class="dropdown-divider"></div>
                </div>
            </div>
        </div> -->

        <div class="btn-group">
            <div class="avatar-container z-depth-1">
                <img [src]="userAvatar?downloadFileApiModel.fullPath() + userAvatar: './assets/icons/default_profile image@3x.png'" onerror="this.onerror=null;this.src='./assets/icons/default_profile image@3x.png';" />
            </div>
            <span class="username">{{username}}</span>
        </div>

        <button (click)="logout()" class="header-btn m-r-80 win-btn-hover">
            <img src="/assets/svg/ic_logout.svg" alt="ic_logout">
        </button>
    </div>
</div>