import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// import { environment } from "../environments/environment";
import { AppPath } from "./base/constant";
import { PublicComponent, PUBLIC_ROUTES } from "./layout/public";
import { SecureComponent, SECURE_ROUTES } from "./layout/secure";

const routes: Routes = [
  {
    path: '',
    redirectTo: AppPath.slash + AppPath.home.path,
    pathMatch: 'full'
  },
  {
    path: '',
    component: PublicComponent,
    children: PUBLIC_ROUTES
  },
  {
    path: '',
    component: SecureComponent,
    children: SECURE_ROUTES
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import("./pages/not_found/not.found.module").then(m => m.NotFoundModule),
  },
  {
    path: AppPath.cannotAccess.path,
    loadChildren: () => import("./pages/cannot_access/cannot.access.module").then(m => m.CannotAccessModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        useHash: true,
        relativeLinkResolution: "legacy",
        onSameUrlNavigation: "reload",
        scrollPositionRestoration: "enabled",
        scrollOffset: [0, 0],
        // enableTracing: !environment.production
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
