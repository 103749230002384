import { Component } from "@angular/core";

@Component({
  selector: "app-secure",
  templateUrl: "secure.html",
  styleUrls: ["secure.scss"]
})
export class SecureComponent {

  constructor() { }

  ngOnInit() {
  }

}
