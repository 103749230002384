import { Component } from "@angular/core";

@Component({
  selector: "app-public",
  templateUrl: "public.html",
  styleUrls: ["public.scss"]
})
export class PublicComponent {

  constructor() { }

  ngOnInit() {
  }

}
