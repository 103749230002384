import { Routes } from '@angular/router';
import { AppPath } from '../../base/constant';

export const SECURE_ROUTES: Routes = [
    // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    // {
    //     path: "",
    //     redirectTo: appPath.slash + appPath.home.path,
    //     pathMatch: "full"
    //   },
      {
        path: AppPath.organization.path,
        loadChildren: () => import("../../pages/organization/organization.module").then(m => m.OrganizationModule)
      },
      {
        path: AppPath.item.path,
        loadChildren: () => import("../../pages/item/module").then(m => m.Module)
      },
      {
        path: AppPath.user.path,
        loadChildren: () => import("../../pages/user/user.module").then(m => m.UserModule)
      },
      {
        path: AppPath.event.path,
        loadChildren: () => import("../../pages/event/event.module").then(m => m.EventModule)
      },
      {
        path: AppPath.profile.path,
        loadChildren: () => import("../../pages/profile/profile.module").then(m => m.ProfileModule)
      },
      {
        path: AppPath.main.path,
        loadChildren: () => import("../../pages/home/home.module").then(m => m.HomeModule)
      },
      {
          path: AppPath.support.path,
          loadChildren: () => import("../../pages/support/support.module").then(m => m.SupportModule)
      },
      {
          path: AppPath.unassignedSupport.path,
          loadChildren: () => import("../../pages/unassigned_support/module").then(m => m.UnassignedSupportModule)
      },
];