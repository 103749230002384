import { Component, OnInit } from "@angular/core";
import { SidebarConfig } from "./sidebar.config";
import { Router } from "@angular/router";
import { Broadcast, EVENT } from "../../base/broadcast";
import { TokenDto } from "../../pages/login/dto/token.dto";
import { LocalStorageService } from "../../base/local.storage.service";
import { ObjectUtils } from "../../utilities/object.utils";
import { AppPath, PageMode } from "../../base/constant";
import { SidebarDto } from "./dto/sidebar.dto";
import { RoleUtils } from "../../utilities/role.utils";

@Component({
  selector: "sd-sidebar",
  templateUrl: "./sidebar.html",
  styleUrls: ["./sidebar.scss"]
})
export class SidebarComponent implements OnInit {
  public isShow: boolean = false;
  public isExpanded: boolean = false;
  public selectedItem: string = "home.sidebar.dashboard";
  public sidebarConfig: SidebarConfig = new SidebarConfig(this.localStorageService);
  token: TokenDto;

  constructor(
    private router: Router,
    public broadcast: Broadcast,
    private objectUtils: ObjectUtils,
    private localStorageService: LocalStorageService
  ) {
      this.token = this.localStorageService.get("tokenObj");
  }

  ngOnInit(): void {
    this.broadcast.on<SidebarDto>(EVENT.UPDATE_SIDEBAR_SELECTED).subscribe(sidebarObj => {
      this.selectedItem = "home.sidebar." + (sidebarObj.name === AppPath.restaurant.path ? AppPath.organization.path : sidebarObj.name);
      this.reloadView();
    });

    //set default page
    let routeName = this.router.url.split("/")[1];
    this.selectedItem = "home.sidebar." + (routeName == AppPath.restaurant.path ? AppPath.organization.path : routeName);
    this.broadcast.on<SidebarDto>(EVENT.COLLAPSE_SIDE_BAR).subscribe(_sidebarObj => {
      if (document.getElementById("left-side-page")) { 
        document.getElementById("left-side-page")!.style.width = "80px";
      }
      if (document.getElementById("right-side-page")) { 
        document.getElementById("right-side-page")!.style.marginLeft = "0";
      }
      this.isExpanded = false;
    });

    this.reloadView();
  }

  reloadView(): void {
    this.token = this.localStorageService.get("tokenObj");
    if (this.objectUtils.isValid(this.token) && this.objectUtils.isValid(this.token.roles)) {
      this.sidebarConfig.loadSidebarConfigByRole(this.token.roles!);
    }
  }

  gotoPage(pageObj: any) {
    this.router.navigate([pageObj.url]);
    if (this.isExpanded == true) {
      this.isExpanded = false;
    }
  }

  goHome() {
    let mode = this.localStorageService.get("mode"),
      url: string,
      userDetail = this.localStorageService.get("userDetail");

    if (mode.pageMode) {
      url = (mode.pageMode === PageMode.MEMBER_MODE && RoleUtils.getInstance().hasMultipleMode(this.localStorageService)) ? AppPath.slash + AppPath.main.my.path : AppPath.slash + AppPath.home.path;
    } else if (RoleUtils.getInstance().hasMemberRole(this.localStorageService) && userDetail.userRoles.length === 1) {
      url = AppPath.slash + AppPath.main.my.path;
    } else {
      url = AppPath.slash + AppPath.main.path;
    }

    this.router.navigate([url]);
  }

  toggleSideBar() {
    let sidebarWidth = this.isExpanded ? "80px" : "300px";
    let mainMarginLeft = this.isExpanded ? "80px" : "300px";
    setTimeout(() => {
      if (document.getElementById("left-side-page")) { 
        document.getElementById("left-side-page")!.style.width = sidebarWidth;
      }
      if (document.getElementById("right-side-page")) { 
        document.getElementById("right-side-page")!.style.marginLeft = mainMarginLeft;
      }
      this.isExpanded = !this.isExpanded;
    }, 300);
  }
}
