import { Injectable } from '@angular/core';
import {map, filter} from 'rxjs/operators';
import {Subject, Observable} from 'rxjs';
// import { Observable } from 'rxjs/internal/Observable';
// import { filter } from 'rxjs/internal/operators/filter';
// import { map } from 'rxjs/internal/operators/map';
// import { Subject } from 'rxjs/internal/Subject';

export interface IBroadcast {
  key: EVENT;
  data?: any;
}

@Injectable({ providedIn : "platform"})
export class Broadcast {
  private _eventBus: Subject<IBroadcast>;

  constructor() {
    this._eventBus = new Subject<IBroadcast>();
  }

  broadcast(key: EVENT, data?: any) {
    this._eventBus.next({ key, data });
  }

  on<T>(key: EVENT): Observable<T> {
    return this._eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => <T>event.data),);
  }
}

export enum EVENT {
  SHOW_SPINNER,
  HIDE_SPINNER,
  MENU_COLLAPSED,
  UPDATE_SIDEBAR_SELECTED,
  COLLAPSE_SIDE_BAR,
  LANGUAGE_CHANGE,
  ORG_STATUS_CHANGE,
  LOGGED_IN,
  CHANGE_EVENT_STATUS,
  TICKET_VERIFIED,
  CHANGE_AVATAR
}
