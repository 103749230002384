import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { AppConfig } from "./base/constant";
import { HeaderBarComponent } from "./components/headerbar/header.bar";
import { SidebarComponent } from "./components/sidebar/sidebar";
import { SpinnerComponent } from "./components/spinner/spinner";
import { UpdatesNotificationComponent } from "./components/updates_notification/updates.notification";
import { PublicComponent } from "./layout/public";
import { SecureComponent } from "./layout/secure";
import { AppCoreModule } from "./shared/app.core.module";
import { ServicesCoreModule } from "./shared/services.core.module";
import { ToastModule } from "./components/toast/toast.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "assets/i18n/", ".json");
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: AppConfig.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    AppRoutingModule,
    AppCoreModule.forRoot(),
    ServicesCoreModule.forRoot(),
  ],
  providers: [
  ],
  // entryComponents: [ToastComponent],
  declarations: [
    AppComponent,
    PublicComponent,
    SecureComponent,
    UpdatesNotificationComponent,
    SpinnerComponent,
    HeaderBarComponent,
    SidebarComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
