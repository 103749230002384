<div id="right-side-page">
  <div id="page-headerbar">
      <sd-header-bar></sd-header-bar>
  </div>
  <div id="main" role="main">
      <div class="content-page">
          <router-outlet style="width: 100%"></router-outlet>
      </div>
  </div>
</div>
<div id="left-side-page">
  <div id="page-sidebar">
      <sd-sidebar></sd-sidebar>
  </div>
</div>