import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { merge, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Observable } from 'rxjs/internal/Observable';
// import { merge } from 'rxjs/internal/observable/merge';
// import { of } from 'rxjs/internal/observable/of';
// import { map } from 'rxjs/internal/operators/map';
// import { Subject } from 'rxjs/internal/Subject';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'updates-notification',
    templateUrl: './updates.notification.html',
    styleUrls: ['./updates.notification.scss'],
})
export class UpdatesNotificationComponent {
    updateAvailable$: Observable<boolean>;
    closed$ = new Subject<void>();

    constructor(private updates: SwUpdate) {
        this.updateAvailable$ = merge(
            of(false),
            this.updates.available.pipe(map(() => true)),
            this.closed$.pipe(map(() => false)),
        );
    }

    activateUpdate() {
        if (environment.production) {
            this.updates.activateUpdate().then(() => {
                location.reload();
            });
        }
    }
}