import { StatusDto } from "../shared/dto/status.dto";
import { EventTypeDto } from "../pages/event/dto/event.type.dto";

export const TIME_ZONE_DEFAULT: string = "Asia/Ho_Chi_Minh";

export enum TARGET_TYPE { 
  USER = "USER",
  ITEM = "ITEM",
  UNIT = "UNIT",
  ORG = "ORG",
  EVENT = "EVENT",
  SUPPORT = "SUPPORT",
}

export enum REPEAT_TYPE {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY"
}

export enum PLANNING{
  STAYING = "STAYING",
  THREE_MONTHS = "THREE_MONTHS",
  NEXT_YEAR = "NEXT_YEAR",
  OTHER_YEAR = "OTHER_YEAR"
};

export enum STAY_TYPE{
  ORIGINAL = "ORIGINAL",
  LONG_TIME = "LONG_TIME",
  SORT_TIME = "SORT_TIME",
  TRAVEL = "TRAVEL"
};

export enum FREQUENCY{
  USUALLY = "USUALLY",
  SOMETIME = "SOMETIME",
  RARELY = "RARELY",
  OTHER = "OTHER"
};

export enum WINE_FREQUENCY{
  USUALLY = "USUALLY",
  SOMETIME = "SOMETIME",
  RARELY = "RARELY"
};

export enum VISIT_FREQUENCY{
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY"
};

export enum INTERESTING { 
  OPTION_1 = "OPTION_1",
  OPTION_2 = "OPTION_2",
  OPTION_3 = "OPTION_3",
  OPTION_4 = "OPTION_4",
  OPTION_5 = "OPTION_5",
  OPTION_6 = "OPTION_6"
}

export const AppConfig = {
  app: "WIN",
  defaultLanguage: "vi",
  defaultCurrency: "VND",
  availableLanguages: [
    {
      name: "Tiếng Việt",
      value: "vi"
    }, {
      name: "English",
      value: "en"
    }
  ]
};

export const MapConfig = {
  defaultLattitude: undefined,
  defaultLongtitude: undefined,
  defaultTitle: "",
  mapEventNames: {
    search: "search",
    myLocation: "myLocation",
    mapClick: "mapClick",
    markerDrag: "markerDrag"
  },
  myLocation: "MAP_MY_LOCATION"
};

export const DATE_FORMAT: string = "hh:mma dd/MM/yyyy";
export const TIME_FORMAT: string = "hh:mma";
export const DATE_FORMAT_WITHOUT_TIME: string = "dd/MM/yyyy";

export const Statuses: StatusDto[] = [
  new StatusDto("status.all", "all"),
  new StatusDto("status.new", "new"),
  new StatusDto("status.active", "active"),
  new StatusDto("status.inactive", "inactive"),
  new StatusDto("status.deleted", "deleted")
];

export const Status = {
  NEW: "NEW",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DELETED: "DELETED",
  EXPIRED: "EXPIRED",
  VERIFIED: "VERIFIED",
  DONE: "DONE",
  IN_PROGRESS: "IN_PROGRESS",
  WAITING_REVIEW: "WAITING_REVIEW"
};

export const EventTypes: EventTypeDto[] = [
  new EventTypeDto("INVITATION", "INVITATION"),
  new EventTypeDto("PUBLIC", "PUBLIC"),
  new EventTypeDto("PRIVATE", "PRIVATE"),
  new EventTypeDto("SURPRISE", "SURPRISE")
];

export const EventType = {
  INVITATION: "INVITATION",
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
  SURPRISE: "SURPRISE"
};

export const Role = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
  MANAGER: "MANAGER",
  ORG_ADMIN: "ORG_ADMIN"
};

export const Authority = {
  ALL_RESTAURANT: "ALL_RESTAURANT",
  ALL_ORG: "ALL_ORG",
  ALL_WINE: "ALL_WINE",
  ALL_USER: "ALL_USER",
  ALL_EVENT: "ALL_EVENT",
  ACCESS_CRT: "ACCESS_CRT"
};

export const Gender = {
  MALE: "MALE",
  FEMALE: "FEMALE"
};

export const PageMode = {
  MEMBER_MODE: "MEMBER_MODE",
  ADMIN_MODE: "ADMIN_MODE"
};

export const RequestAssignee = {
  MANAGER: "MANAGER",
  ORG: "ORG",
  ADMIN: "ADMIN"
};
export const SUPPORT_TYPE = {
  NONE: "NONE",
  RECEIVED: "RECEIVED",
  GIVING: "GIVING",
  TRANSPORT: "TRANSPORT",
};

export enum GENERAL { 
  NONE = "NONE",
  TRUE = "TRUE",
  FALSE = "FALSE",
}

export const AppPath = {
  slash: "/",
  home: {
    path: "home"
  },
  register: {
    path: "register"
  },
  resetPassword: {
    path: "resetpassword/:token"
  },
  policy: {
    path: "policy"
  },
  forgotPassword: {
    path: "forgotpassword"
  },
  login: {
    path: "login"
  },
  about: {
    path: "about"
  },
  profile: {
    path: "profile",
    roles: []
  },
  restaurant: {
    path: "restaurant",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN, Role.ORG_ADMIN, Role.MANAGER
    ],
    action: {
      path: "action",
      addnew: {
        path: ":orgId/action"
      },
      galleryUpload: {
        path: "galleryUpload"
      },
      update: {
        path: ":orgId/action/:id"
      },
      view: {
        path: ":orgId/view/:id"
      }
    },
    my: {
      path: "my",
      action: {
        view: {
          path: "my/:orgId/view/:id",
          roles: [Role.MANAGER]
        }
      }
    }
  },
  user: {
    path: "user",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN, Role.ORG_ADMIN
    ],
    action: {
      path: "action",
      active: {
        path: "active/:token"
      },
      addnew: {
        path: ":orgId/action"
      },
      update: {
        path: ":orgId/action/:id"
      },
      import: {
        path: "import"
      },
      download: {
        path: "download"
      }
    }
  },
  event: {
    path: "event",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN
    ],
    action: {
      path: "event",
      addnew: {
        path: "action"
      },
      view: {
        path: "view/:id",
        roles: [Role.SUPER_ADMIN, Role.ADMIN]
      },
      detail: {
        path: "s/:barCode"
      }
    },
    my: {
      path: "my",
      roles: [
        Role.ORG_ADMIN, Role.MANAGER
      ],
      action: {
        view: {
          path: "my/view/:id",
          roles: [Role.ORG_ADMIN, Role.MANAGER]
        }
      }
    },
    publish: {
      path: ":id",
      register: {
        path: "register/:id"
      },
      information: {
        path: "information/:code"
      },
      menu: {
        path: "menu",
        foodsMenu: {
          path: "foods"
        },
        winesMenu: {
          path: "wines"
        }
      }
    }
  },
  wine: {
    path: "wine",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN, Role.ORG_ADMIN, Role.MANAGER
    ],
    action: {
      addnew: {
        path: "action"
      },
      update: {
        path: "action/:id"
      },
      view: {
        path: "view/:id"
      }
    },
    assign: {
      path: ":id/assign"
    },
    my: {
      path: "my",
      roles: [Role.ORG_ADMIN]
    }
  },
  main: {
    path: "main",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN, Role.ORG_ADMIN, Role.MANAGER
    ],
    my: {
      path: "myWine",
      roles: [Role.MEMBER]
    }
  },
  item: {
    path: "item",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN, Role.ORG_ADMIN
    ],
    action: {
      addnew: {
        path: "action"
      },
      update: {
        path: "action/:id"
      },
      view: {
        path: "view/:id"
      }
    },
  },
  unassignedSupport: {
    path: "unassignedSupport",
    roles: [
      Role.ORG_ADMIN, Role.MANAGER
    ],
  },
  support: {
    path: "support",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN, Role.ORG_ADMIN, Role.MANAGER
    ],
    action: {
      addnew: {
        path: "action"
      },
      update: {
        path: "action/:id"
      },
      view: {
        path: "view/:id",
        roles: [
          Role.SUPER_ADMIN, Role.ADMIN, Role.ORG_ADMIN, Role.MANAGER
        ]
      },
      publish: {
        path: "publish"
      },
      unpublish: {
        path: "unpublish"
      },
    },
  },
  myTickets: {
    path: "myTickets",
    roles: [Role.MEMBER]
  },
  myRestaurant: {
    path: "myRestaurant",
    roles: [Role.MEMBER]
  },
  mySearchEvent: {
    path: "mySearchEvent",
    roles: [Role.MEMBER]
  },
  cannotAccess: {
    path: "cannotAccess"
  },
  myContract: {
    path: "myContract",
    roles: [Role.MEMBER],
    myExpiredContract: {
      path: "myExpiredContract/:canGoBack",
      roles: [Role.MEMBER]
    }
  },
  verify: {
    path: "verify",
    roles: [Role.ORG_ADMIN, Role.MANAGER]
  },
  organization: {
    path: "organization",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN
    ],
    action: {
      addnew: {
        path: "action"
      },
      update: {
        path: "action/:id"
      },
      view: {
        path: "view/:id"
      }
    },
    my: {
      path: "my",
      roles: [Role.ORG_ADMIN, Role.MANAGER]
    }
  },
  contract: {
    path: "contract",
    roles: [
      Role.SUPER_ADMIN, Role.ADMIN
    ],
    action: {
      addnew: {
        path: "action"
      },
      update: {
        path: "action/:id"
      },
      view: {
        path: "view/:id",
        roles: [
          Role.SUPER_ADMIN, Role.ADMIN
        ]
      }
    },
    assignWine: {
      path: ":id/assignwine"
    }
  },
  promotion: {
    path: "promotion",
    action: {
      view: {
        path: "view/:id/menu/:resId"
      }
    }
  }
};