import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpRequestClient } from "./base/http.request.client";
import { TokenDto } from "./pages/login/dto/token.dto";
import { ApiDto } from "./shared/dto/api.dto";
import { Observable } from "rxjs";
import { API } from "./base/api";
import { PARAM } from "./enum/param";

@Injectable({ providedIn: "any" })
export class AppBusiness {
  constructor(private http: HttpRequestClient) { }

  refreshToken(refreshToken: string): Observable<TokenDto> {
    let getTokenApi: ApiDto = new ApiDto(API.PUBLISH.GET_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.set(
      "Authorization",
      "Basic Y2hhcml0eS1jbGllbnQ6Y2hhcml0eS1zZWNyZXQ="
    );

    const body = new HttpParams()
      .set("refresh_token", refreshToken)
      .set("grant_type", "refresh_token");
    const params = new HttpParams()
      .set(PARAM.NONE_AUTHENTICATION, "true");
    return this.http.post<TokenDto>(
      getTokenApi.fullTokenPath,
      body.toString(),{
        headers: headers,
        params: params
      }
    );
  }
}
