import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router'; // import Router and NavigationEnd
declare let ga: Function; // Declare ga as a function

@Injectable({ providedIn:"root" })
export class GoogleAnalyticsService {


    //************************
    // Import this to app.component.ts
    // subscribe to router events and send page views to Google Analytics
    // this.router.events.subscribe(event => {
    //     GoogleAnalyticsService.subscribeRouterEvents(event);
    //   });
    //************************
    constructor() { }

    // /**
    //   * Emit google analytics event
    //   * Fire event example:
    //   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
    //   * param {string} eventCategory
    //   * param {string} eventAction
    //   * param {string} eventLabel
    //   * param {number} eventValue
    //   */
    public eventEmitter(eventCategory: string, eventAction: string, eventLabel: string | undefined = undefined, eventValue: number | undefined = undefined) {
        ga('send', 'event', {
            eventCategory: eventCategory,
            eventLabel: eventLabel,
            eventAction: eventAction,
            eventValue: eventValue
        });
    }

    subscribeRouterEvents(event: any) {
        if (event instanceof NavigationEnd) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
        }
    }
}
