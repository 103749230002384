import { Role } from "../base/constant";
import { LocalStorageService } from "../base/local.storage.service";
import { TokenDto } from "../pages/login/dto/token.dto";
import { UserRoleDto } from "../pages/user/dto/user.role.dto";

export class RoleUtils {
  private static instance: RoleUtils = new RoleUtils();

  constructor(
  ) { }

  public static getInstance(): RoleUtils {
    if (!RoleUtils.instance) {
      RoleUtils.instance = new RoleUtils();
    }
    return RoleUtils.instance;
  }

  managerRole(roles: Array<string>): boolean {
    let arr = roles.filter((r) => {
      return r === Role.MANAGER;
    });
    return arr.length > 0;
  }

  checkManagerRole(roles: Array<UserRoleDto>): boolean {
    let arr = roles.filter((r) => {
      return r.role === Role.MANAGER;
    });
    return arr.length > 0;
  }

  orgAdminRole(roles: Array<string>): boolean {
    let arr = roles.filter((r) => {
      return r === Role.ORG_ADMIN;
    });
    return arr.length > 0;
  }

  checkOrgAdminRole(roles: Array<UserRoleDto>): boolean {
    let arr = roles.filter((r) => {
      return r.role === Role.ORG_ADMIN;
    });
    return arr.length > 0;
  }

  adminGroup(roles: Array<string>): boolean {
    let arr = roles.filter((r) => {
      return r === Role.ADMIN || r === Role.SUPER_ADMIN;
    });
    return arr.length > 0;
  }

  checkAdminGroup(roles: Array<UserRoleDto>): boolean {
    let arr = roles.filter((r) => {
      return r.role === Role.ADMIN || r.role === Role.SUPER_ADMIN;
    });
    return arr.length > 0;
  }

  onlyManager(localStorageService: LocalStorageService) { 
    return (!this.hasAdminGroupRole(localStorageService) && !this.hasOrgAdminRole(localStorageService) && !this.hasManagerRole(localStorageService));
  }

  onlyOrgAmin(localStorageService: LocalStorageService) { 
    return (!this.hasAdminGroupRole(localStorageService) && this.hasOrgAdminRole(localStorageService));
  }

  hasSingleRole(localStorageService: LocalStorageService): boolean {
    let token: TokenDto = localStorageService.get("tokenObj");
    return token && token.roles && token.roles.length === 1 ? true : false;
  }

  hasMultipleMode(localStorageService: LocalStorageService): boolean {
    return (this.hasAdminGroupRole(localStorageService) || this.hasOrgGroupRole(localStorageService)) && this.hasMemberRole(localStorageService);
  }

  hasOrgGroupRole(localStorageService: LocalStorageService): boolean {
    let token: TokenDto = localStorageService.get("tokenObj");
    if (token && token.roles) {
      let role: string | undefined = token.roles.find((r) => {
        return (r === Role.ORG_ADMIN || r === Role.MANAGER);
      });
      return role !== undefined && role !== null;
    }
    return false;
  }

  hasAdminGroupRole(localStorageService: LocalStorageService): boolean {
    let token: TokenDto = localStorageService.get("tokenObj");
    if (token && token.roles) {
      let role: string | undefined = token.roles.find((r) => {
        return (r === Role.SUPER_ADMIN || r === Role.ADMIN);
      });
      return role !== undefined && role !== null;
    }
    return false;
  }

  hasMemberRole(localStorageService: LocalStorageService): boolean {
    return this.hasRole(localStorageService.get("tokenObj"), Role.MEMBER);
  }

  hasManagerRole(localStorageService: LocalStorageService): boolean {
    return this.hasRole(localStorageService.get("tokenObj"), Role.MANAGER);
  }

  hasOrgAdminRole(localStorageService: LocalStorageService): boolean {
    return this.hasRole(localStorageService.get("tokenObj"), Role.ORG_ADMIN);
  }

  hasAdminRole(localStorageService: LocalStorageService): boolean {
    return this.hasRole(localStorageService.get("tokenObj"), Role.ADMIN);
  }

  hasSuperAdminRole(localStorageService: LocalStorageService): boolean {
    return this.hasRole(localStorageService.get("tokenObj"), Role.SUPER_ADMIN);
  }

  hasRole(token?: TokenDto, role: string = ""): boolean {
    if (token && Array.isArray(token.roles) && role.length > 0) {
      let find: string | undefined = token.roles.find((r) => {
        return (r === role);
      });
      return find !== undefined && find !== null;
    }
    return false;
  }

}
