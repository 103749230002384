import { ApplicationRef, Component } from '@angular/core';
import { ToastNoAnimation, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: '[via-toast]',
  templateUrl: "toast.html",
  styleUrls: ["toast.scss"]
})
export class ToastComponent extends ToastNoAnimation {
  listMessages: string[];

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    protected appRef: ApplicationRef
  ) {
    super(toastrService, toastPackage, appRef);
    this.listMessages = this.message ? this.message.toString().split("||") : [];
  }
}
