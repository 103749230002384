import { AppConfig } from "../base/constant";
import { LocalStorageService } from "../base/local.storage.service";
import { TokenDto } from "../pages/login/dto/token.dto";

export class AppStorageUtils {
  private static instance: AppStorageUtils = new AppStorageUtils();

  constructor(
  ) { }

  public static getInstance(): AppStorageUtils {
    if (!AppStorageUtils.instance) {
      AppStorageUtils.instance = new AppStorageUtils();
    }
    return AppStorageUtils.instance;
  }

  public getUserCode(localStorageService: LocalStorageService): string {
    let token: TokenDto | undefined = localStorageService.get("tokenObj");
    if (token) {
      return token.userCode;
    }
    return "";
  }

  public getOrgCode(localStorageService: LocalStorageService): string {
    let token: TokenDto | undefined = localStorageService.get("tokenObj");
    if (token) {
      return token.orgCode;
    }
    return "";
  }

  public getCurrentLanguageCode(localStorageService: LocalStorageService): string {
    let languageCode: string = localStorageService.get("language");
    if (!languageCode) {
      languageCode = AppConfig.defaultLanguage;
    }
    return languageCode;
  }

  public getAuthorization(localStorageService: LocalStorageService): string { 
    return localStorageService.get("tokenObj").token_type + " " + localStorageService.get("tokenObj").access_token;
  }

  //logout
  removeAllLoginInfo(localStorageService: LocalStorageService) {
    localStorageService.removeByname("access_token");
    localStorageService.removeByname("tokenObj");
    localStorageService.removeByname("userAvatar");
    localStorageService.removeByname("userDetail");
    localStorageService.removeByname("tokenExpireIn");
    //this.localStorageService.removeByname("lastSuccessUrl");
    sessionStorage.removeItem("lastSuccessUrl");
  }
  
}
