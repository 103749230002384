export const API = {
  PUBLISH: {
    ITEM_LIST: "item/list",
    SUPPORT: "support",
    SUPPORT_LIST: "support/list",
    SUPPORT_LOCATION_LIST: "support/location/list",
    SUPPORT_DETAIL: "support/",
    // SUPPORT_DETAIL_V2: "support/list/detail",
    SUPPORT_SUMMARY: "support/summary",
    GROUP_LIST: "group/list",

    ACTIVATE_USER: "user/activate",
    GET_TOKEN: "charity/oauth/token",
    GET_USER_BY_TOKEN: "user/token/",
    FORGOT_PASSWORD: "user/password/forgot",
    RESET_PASSWORD_BY_TOKEN: "user/password/reset",
    
    GENERATE_OTP_RESET_PASSWORD: "otp",
    VERIFY_OTP: "otp/verify",
    RESET_PASSWORD_BY_OTP: "otp/reset/password",

    GENERATE_GOOGLE_QR: "https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=",

    DOWNLOAD_PUBLISH_FILE: "common/file/download/public?none-authentication=true&path=",

    GET_EVENT_PUBLISH: "event/:eventCode/register",
    REGISTER_EVENT_PUBLISH: "event/:eventCode/register",

    GET_ANONYMOUS_EVENT: "reservation/:eventInstanceCode/event",
  },
  DOWNLOAD_FILE: "common/file/download?path=",
  UPLOAD_FILE: "common/file/upload?fileType=:fileType",
  UPLOAD_FILE_WITH_TOKEN: "common/file/upload/by-token?userToken=:userToken",
  GENERATE_QR: "common/qr/generate",

  GET_COUNTRIES: "country/all",
  GET_CURRENCY: "currency/all",

  GET_CLAIM_DETAIL: "claim/:claimCode",

  CREATE_ORG: "org",
  GET_ORG: "org/:orgCode",
  MY_ORG: "org/my",
  ACTIVE_ORG: "org/:orgCode/activate",
  INACTIVE_ORG: "org/:orgCode/deactivate",
  DELETED_ORG: "org/:orgCode/delete",
  ORG_SUGGESTION: "org/suggestion",
  SEARCH_ORGS: "org/search",
  SUGGESTION_ORG_WINE: "org/:orgCode/wine/suggestion",
  SEARCH_WINE_ASSIGNED_FOR_ORGS: "org/:orgCode/wines",
  SUGGESTION_ORG_RES: "org/:orgCode/restaurant/suggestion",
  CHECK_ORG_WINE_EXPIRE: "org/:orgCode/wine/expired",
  SEARCH_WINE_FOR_ASSIGN_TO_RES: "org/:orgCode/restaurant/:restaurantCode/wine/suggestion",
  ASSIGN_WINES_TO_ORG: "org/:code/assign/wine",

  CREATE_RESTAURANT: "restaurant",
  GET_RESTAURANT: "restaurant/:id",
  UPDATE_RESTAURANT: "restaurant/:resCode",
  ACTIVE_RESTAURANT: "restaurant/:resCode/activate",
  INACTIVE_RESTAURANT: "restaurant/:resCode/deactivate",
  DELETED_RESTAURANT: "restaurant/:resCode/delete",
  SEARCH_RESTAURANT: "restaurant/search",
  SEARCH_RESTAURANT_FOR_EVENT: "restaurant/suggestion",
  SEARCH_AUTO_SUGGESTION_RESTAURANT_BY_CRITERIA: "restaurant/suggestion/available",
  SEARCH_AUTO_SUGGESTION_RESTAURANT_EXCLUDE: "restaurant/suggestion/exclude/wine",
  SEARCH_CONTRACT_BOOKING_RESTAURANT: "restaurant/search/wine-bank",
  SEARCH_WINE_ASSIGNED_FOR_RES: "restaurant/:code/wines",
  SEARCH_MANAGER_CLAIM_LIST: "restaurant/:restaurantCode/bookings?limit=:limit&offset=:offset",
  SEARCH_MANAGER_CLAIM_LIST_ALL: "restaurant/:restaurantCode/claims?limit=:limit&offset=:offset",
  SEARCH_MY_RESTAURANT: "restaurant/my-restaurants",
  SEARCH_MY_RECENT_VISITED_RESTAURANT: "restaurant/recently-visited",
  SEARCH_RUNNING_EVENT_FOR_RESTAURANT: "restaurant/:restaurantCode/running-events",
  TOGGLE_WINE_BANK: "restaurant/:code/wine-bank/toggle",
  SUGGESTION_LINKED_CONTRACT_RES: "restaurant/suggestion/wine-bank",
  CHECK_RES_WINE_EXPIRE: "restaurant/:restaurantCode/wine/expired",
  SUGGESTION_RES_WINE: "restaurant/:restaurantCode/wine/suggestion",
  ASSIGN_WINES_TO_RES: "restaurant/:code/assign/wine",

  SEARCH_RESERVATION: "reservation/search",
  SEARCH_RESERVATION_BOOKING: "reservation/restaurant/booking/search",
  SEARCH_MEMBER_RESERVATION: "reservation/my-reservations",
  TICKET_BOOKING: "reservation/:eventInstanceCode/booking",
  EXPIRED_RESERVATION: "reservation/:eventInstanceCode/deactivate",
  ACTIVE_RESERVATION: "reservation/:eventInstanceCode/activate",
  VERIFY_BAR_CODE: "reservation/:barCode/restaurant/:restaurantCode/verify",
  GET_RESERVATION_WINES_LIST: "reservation/:barCode/restaurant/:restaurantCode/wines",
  GET_BOOKING: "reservation/:eventInstanceCode",
  COMPLETE_VERIFY_BAR_CODE: "reservation/:barCode/restaurant/:restaurantCode/wine/:wineCode/complete",
  COMPLETE_VERIFY_PASSCODE: "reservation/:barCode/restaurant/:restaurantCode/wine/:wineCode/complete",
  SEARCH_MY_VERIFIED_RESERVATION: "reservation/my-verified",
  GET_USER_IDENTITIES_RESERVATION: "reservation/identities",
  SEARCH_RESTAURANT_FOR_RESERVATION: "reservation/:barCode/restaurants",

  DOWNLOAD_IMPORT_USERS_TEMPLATE: "user/download/import-user-template",
  CREATE_USER: "user",
  EDIT_USER_ADDRESS: "user/profile/address",
  EDIT_USER_FULLNAME: "user/profile/fullName",
  USER: "user/my",
  ROLES_ACCESS: "user/role",
  ROLES: "user/role/add",
  GET_USER: "user/:userCode",
  SEARCH_USER: "user/search",
  ACTIVE_USER: "user/:userCode/activate",
  INACTIVE_USER: "user/:userCode/deactivate",
  DELETED_USER: "user/:userCode/delete",
  UPDATE_PASSWORD_USER: "user/:userCode/password",
  UPDATE_IMG_USER: "user/profile/img",
  UPDATE_PASSWORD_USER_PROFILE: "user/profile/password",
  UPDATE_PHONE_USER_PROFILE: "user/profile/phone",
  CREATE_PIN_USER_PROFILE: "user/pin",
  UPDATE_PIN_USER_PROFILE: "user/pin/reset",
  UPDATE_EMAIL_USER_PROFILE: "user/profile/email",
  UPLOAD_MEMBER_LIST_FILE: "user/import-users",
  SUGGESTION_USER: "user/suggestion",

  COMPLETE_VERIFY_CONTRACT_NUMBER: "contract/:contractNumber/restaurant/:restaurantCode/complete",
  SEARCH_CONTRACT_AVAILABLE_WINE: "contract/:contractCode/wine/available",
  VERIFY_CONTRACT_NUMBER: "contract/:contractNumber/restaurant/:restaurantCode/verify",
  VERIFY_PASSCODE: "contract/:contractNumber/restaurant/:restaurantCode/verify",
  SEARCH_CONTRACT: "contract/search",
  SEARCH_EXPIRED_CONTRACT: "contract/old-contracts?limit=:limit&offset=:offset",
  GET_CONTRACT: "contract/:contractCode",
  GET_MY_CONTRACT_DETAIL: "contract/my-contract",
  CREATE_CONTRACT: "contract",
  SEARCH_CONTRACT_WINE: "contract/:code/wine",
  SEARCH_CONTRACT_WINE_OF_RESTAURANT: "contract/restaurant/:restaurantCode/wine",
  SEARCH_USER_CLAIM_HISTORY: "contract/:contractCode/claims/history?limit=:limit&offset=:offset",
  UPDATE_CONTRACT_WINE_STATUS: "contract/wineOfContract/action",
  CONTRACT_WINE_EXPIRED: "contract/:contractCode/wine/:wineCode/action/expire",
  CONTRACT_WINE_REASSIGN: "contract/:contractCode/wine/:wineCode/action/reassign",
  ACTIVE_CONTRACT: "contract/:contractCode/activate",
  DELETED_CONTRACT: "contract/:contractCode/delete",
  INACTIVE_CONTRACT: "contract/:contractCode/deactivate",
  ASSIGN_WINES_TO_CONTRACT: "contract/:contractCode/wine/assign",
  EDIT_ASSIGN_WINES_TO_CONTRACT: "contract/:code/wine/:wineCode",
  CHECK_MANDATORY_PIN: "contract/:contractNumber/require/pin?restaurantCode=:restaurantCode",
  CONTRACT_BOOKING: "contract/booking",
  CONTRACT_WINE_RESTAURANT: "contract/wine/:wineCode/restaurant?limit=10&offset=:offset",
  GET_LINKED_CONTRACT_RES: "contract/:contractNumber/linked-restaurants",
  ADD_LINKED_CONTRACT_RES: "contract/:contractNumber/linked-restaurants",

  SEARCH_RESTAURANT_GALLERY: "gallery/search",
  UPLOAD_RESTAURANT_GALLERY: "gallery/restaurant/:resCode",
  UPDATE_INDEX_GALLERY: "gallery/:fromIndex/swap/:toIndex/restaurant/:resCode",
  UPDATE_NAME_GALLERY: "gallery/:imgCode/restaurant/:resCode",
  DELETE_GALLERY_IMG: "gallery/:imgCode/restaurant/:resCode",

  GET_WINE: "wine/:id",
  SEARCH_WINE: "wine/search",
  SUGGESTION_WINE: "wine/suggestion",
  SEARCH_WINE_ASSIGNED_TO_MY_RESTAURANTS: "wine/available/res-manager",
  SEARCH_WINE_ASSIGNED_TO_MY_ORG: "wine/available/org-admin",
  CREATE_WINE: "wine",
  ACTIVE_WINE: "wine/:wineCode/activate",
  ASSIGN_WINE_TO_RESTAURANTS: "wine/:code/assign/restaurant",
  INACTIVE_WINE: "wine/:wineCode/deactivate",
  DELETED_WINE: "wine/:wineCode/delete",
  SEARCH_ASSIGNED_ORGS: "wine/:code/assign/org",
  SEARCH_ASSIGNED_RESTAURANTS: "wine/:wineCode/org/:orgCode/assign/restaurant",
  REASSIGN_WINE: "wine/:wineCode/org/:orgCode/action/reassign",
  EXPIRE_WINE: "wine/:wineCode/org/:orgCode/action/expire",
  PUBLIC_WINE: "wine/:wineCode/org/:orgCode/action/public",
  PRIVATE_WINE: "wine/:wineCode/org/:orgCode/action/private",
  FILLUP_WINE: "wine/:wineCode/org/:orgCode/action/fill-up/:fillUpValue",
  REASSIGN_RES_WINE: "wine/:wineCode/restaurant/:resCode/action/reassign",
  EXPIRE_RES_WINE: "wine/:wineCode/restaurant/:resCode/action/expire",
  PUBLIC_RES_WINE: "wine/:wineCode/restaurant/:resCode/action/public",
  PRIVATE_RES_WINE: "wine/:wineCode/restaurant/:resCode/action/private",
  FILLUP_RES_WINE: "wine/:wineCode/restaurant/:resCode/action/fill-up/:fillUpValue",
  SEARCH_MY_WINE: "wine/my-wines",
  GET_MY_WINE_EVENTS: "wine/:wineCode/event",
  ASSIGN_WINE_TO_ORGS: "wine/:code/assign/org",

  CREATE_EVENT: "event",
  EDIT_EVENT: "event/:eventCode",
  SEARCH_EVENT: "event/search",
  EVENT_SUGGESTION: "event/suggestion",
  GET_EVENT: "event/:eventCode",
  ACTIVE_EVENT: "event/:eventCode/activate",
  INACTIVE_EVENT: "event/:eventCode/deactivate",
  DELETED_EVENT: "event/:eventCode/delete",
  IS_ACTIVE_EXISTED_EVENT: "event/status/active/type/public",
  GET_MEMBER_TICKET_DETAIL: "event/:eventCode/restaurant/:restaurantCode/ticket",
  GET_MEMBER_RESTAURANT_FOR_EVENT: "event/:eventCode/restaurants/list",
  EXPORT_MEMBERS: "event/:eventCode/members/list",
  DOWNLOAD_MEMBERS_TEMPLATE: "event/members/list/template",
  ALLOW_REGISTRATION_ACTIVATE: "event/:eventCode/register/activate",
  ALLOW_REGISTRATION_DEACTIVATE: "event/:eventCode/register/deactivate",
  ALLOW_SEND_SMS_ACTIVATE: "event/:eventCode/sms/activate",
  ALLOW_SEND_SMS_DEACTIVATE: "event/:eventCode/sms/deactivate",

  SEARCH_RM_DASHBOARD_MISSING_WINE: "dashboard/manager/wine/missing",
  SEARCH_RM_DASHBOARD_ORDER_WINE: "dashboard/manager/order",
  SEARCH_RM_DASHBOARD_RESERVATION: "dashboard/manager/claim",
  SEARCH_ORG_DASHBOARD_ORDER_WINE: "dashboard/org/order",
  SEARCH_OADMIN_DASHBOARD_ORDER_WINE: "dashboard/admin/order",

  SEARCH_REQUEST: "order/search",
  NEW_WINE_REQUEST: "order",
  GET_WINE_REQUEST: "order/:id",
  DELETE_WINE_REQUEST: "order/:id/delete",
  DONE_WINE_REQUEST: "order/:id/done",
  ASSIGN_WINE_REQUEST: "order/:id/assign?assignee=",

  DOWNLOAD_INVIATION_MEMBERS_TEMPLATE: "invitation/guest/template",
  UPLOAD_INVIATION_MEMBERS: "invitation/:eventCode/guest",
  DOWNLOAD_INVIATION_MEMBERS: "invitation/:eventCode/guest/download",
  TOGGLE_PRIORITY: "invitation/:eventCode/guest/:code/priority",
  UPDATE_INVITATION: "invitation/:eventCode/guest/:code",

  SEARCH_PASSCODE: "contract/:contract_code/passcode/search",
  GENERATE_PASSCODE: "contract/:contract_code/passcode",
  ACTIVE_PASSCODE: "contract/:contract_code/passcode/:code/activate",
  INACTIVE_PASSCODE: "contract/:contract_code/passcode/:code/deactivate",
  DELETED_PASSCODE: "contract/:contract_code/passcode/:code/delete",

  CREATE_ITEM: "item",
  EDIT_ITEM: "item/:code",
  SEARCH_ITEM: "item/search",
  GET_ITEM: "item/:code",
  ACTIVE_ITEM: "item/:code/activate",
  INACTIVE_ITEM: "item/:code/deactivate",
  DELETED_ITEM: "item/:code/delete",

  UNIT_LIST: "unit/list",
  
  SEARCH_SUPPORT: "support/my",
  SEARCH_UNASSIGNED_SUPPORT: "support/unassigned",
  EDIT_SUPPORT: "support/:code",
  ACTIVE_SUPPORT: "support/:code/approve",
  INACTIVE_SUPPORT: "support/:code/deactivate",
  DELETED_SUPPORT: "support/:code/delete",
  IN_PROGRESS_SUPPORT: "support/:code/inprogress",
  DONE_SUPPORT: "support/:code/done",
  ASSIGN_SUPPORT: "support/assign",
  ITEMS_BY_SUPPORT: "support/:code/item/list",
  PUBLISH_SUPPORT: "support/publish",
  PRIVATE_SUPPORT: "support/private"

};
