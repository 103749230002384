<div id="sidebar">
    <div id="logo" class="logo">
        <a (click)="goHome()">
            <img alt="App Logo" src="/assets/launcher/icon-65x65.png">
        </a>
    </div>
    <div id="list-pages">
        <ul class="list-pages">
            <li *ngFor="let item of sidebarConfig.listPages" class="win-tooltip">
                <div *ngIf="!isExpanded" class="right-tooltip">
                    {{item.name | translate}}
                    <i></i>
                </div>
                <div class="item" (click)="gotoPage(item)">
                    <a class="list-item" [ngClass]="{'active-item': selectedItem==item.name}">
                        <div class="item-container">
                            <div>
                                <img alt="{{ selectedItem==item.name? item.iconActive: item.iconDeactive }}" src="{{ selectedItem==item.name? item.iconActive: item.iconDeactive }}" width="22px" height="22px">
                            </div>
                            <div class="title" *ngIf="isExpanded">
                                {{ item.name | translate}}
                            </div>
                        </div>
                    </a>
                </div>
            </li>
        </ul>
    </div>
    <div id="bumberger-btn">
        <button (click)="toggleSideBar()" class="hamburger-btn">
            <img src="/assets/svg/ic_hamburger.svg" with="22px" alt="ic_hamburger">
        </button>
    </div>
</div>