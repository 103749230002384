import { NgModule } from '@angular/core';
import { ToastComponent } from './toast';
import { CommonModule } from '@angular/common';
import { ToastNoAnimationModule } from 'ngx-toastr';

@NgModule({
  declarations: [ToastComponent],
  imports: [
    CommonModule,
    ToastNoAnimationModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 5000,
      positionClass: "toast-top-left",
      enableHtml: true,
      closeButton: true,
      preventDuplicates: true,
      countDuplicates: true,
      toastComponent: ToastComponent
    }),
  ],
  exports: [ToastComponent]
})
export class ToastModule { }
