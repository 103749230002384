import { Routes } from '@angular/router';
import { AppPath } from '../../base/constant';
import { AlwaysAuthGuard } from '../../components/auth/always.auth.guard';

export const PUBLIC_ROUTES: Routes = [
    // { path: '', redirectTo: 'login', pathMatch: 'full' },
      // {
      //   path: "",
      //   redirectTo: AppPath.slash + AppPath.home.path,
      //   pathMatch: "full"
      // },
    {
        path: "",
        redirectTo: AppPath.slash + AppPath.home.path,
        pathMatch: "full"
    },
    {
        path: AppPath.home.path,
        loadChildren: () => import("../../pages/home/map/map.module").then(m => m.MapModule)
    },
    {
        path: AppPath.user.path + AppPath.slash + AppPath.user.action.active.path,
        // loadChildren: () => import("../../pages/active_user/module").then(m => m.ActiveUserModule),
        // canActivate: [AlwaysAuthGuard]
        loadChildren: () => import("../../pages/reset_password/reset.password.module").then(m => m.ResetPasswordModule),
        canActivate: [AlwaysAuthGuard]
    },
    {
        path: AppPath.register.path,
        loadChildren: () => import("../../pages/register_support/module").then(m => m.RegisterSupportModule)
    },
    {
        path: AppPath.resetPassword.path,
        loadChildren: () => import("../../pages/reset_password/reset.password.module").then(m => m.ResetPasswordModule),
        canActivate: [AlwaysAuthGuard]
    },
    {
        path: AppPath.forgotPassword.path,
        loadChildren: () => import("../../pages/forgot_password/forgot.password.module").then(m => m.ForgotPasswordModule),
        canActivate: [AlwaysAuthGuard]
    },
    {
        path: AppPath.login.path,
        loadChildren: () => import("../../pages/login/login.module").then(m => m.LoginModule),
        canActivate: [AlwaysAuthGuard]
    },
];